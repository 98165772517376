<template>
  <div class="product">
    <div class="cjt">
      <div class="cjt_box">
        <p>村居通</p>
        <p>指尖上的信息动态和村务监督</p>
        <p>工作动态公开丨随时监督村务丨激发居民参与基层治理的热情</p>
        <div class="btn">在线咨询</div>
      </div>
    </div>
    <div class="hxld">
      <p>核心亮点</p>
      <div class="hxld_box">
        <img class="img" id="img" src="@/assets/images/product/cjt/hxld.png" />
        <div class="title" id="title">
          <img class="icon" src="@/assets/images/product/cjt/hxld_icon.png" />
          <p class="first_line">乡村振兴</p>
          <p class="second_line">
            了解本村人居环境整治情况，查看涉农补助、涉农政策，获取技术指导知识
          </p>
        </div>
      </div>
    </div>
    <div class="cwjd">
      <div class="title" id="title2">
        <img class="icon" src="@/assets/images/product/cjt/cwjd_icon.png" />
        <p class="first_line">村务监督</p>
        <p class="second_line">
          了解本村集体财务公开、集体三资管理、涉农惠农资金情况，监督村居施工工程，打通举报公职人员的线上渠道
        </p>
      </div>
      <img class="img2" id="img2" src="@/assets/images/product/cjt/cwjd.png" />
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {

    }
  },
  mounted () {
    $(window).scroll(this.scrollBar)
  },
  methods: {
    scrollBar () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      var cwjdH = $('.cwjd').offset().top
      if (h > cwjdH - 500) {
        $('#img2').addClass('animation')
        $('#title2').addClass('animation')
      }
    }
  },
  destroyed () {
    $(window).unbind('scroll', this.scrollBar)
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
